.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 40px;
  border-radius: 4px;
  padding: 10px 20px;
  background-color: #fae2de;
  width: 400px;
  height: 355px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.card h5 {
  margin: 0;
}

.name {
  margin: 0;
  text-transform: capitalize;
}

.icon {
  display: flex;
  gap: 5px;
}

.iconpic {
  border-radius: 100%;
  max-width: 25px;
}

.img {
  width: 150px;
  height: 150px;
}

.card h4 {
  margin-bottom: 5px;
}

.abilities {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.favorite {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.evolve {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card span {
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
}

.pokemonEvolution {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.pokemonEvolution :hover {
  opacity: 0.75;
}

.pokeball {
  width: 30px;
  align-self: flex-end;
  cursor: pointer;
}
