.Home {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btn {
  border-radius: 4px;
  border: 1px solid rgb(186, 184, 184);
  background-color: #fae2de;
  margin: 0 0 30px;
  padding: 5px;
  width: 120px;
  cursor: pointer;
}
