.login {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 40px;
  border-radius: 4px;
  border: 10px solid rgb(192, 192, 192);
  padding: 10px 20px;
  background-color: #fae2de;
  width: 400px;
  height: 355px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.login h5 {
  margin: 0;
}

.name {
  margin: 0;
}

.icon {
  display: flex;
  max-width: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0 50px;
}

.input {
  border-radius: 4px;
  padding: 7px;
  border: 1px solid rgb(186, 184, 184);
}

.btn {
  border-radius: 4px;
  border: 1px solid rgb(186, 184, 184);
  background-color: #fae2de;
  margin: 0 0 30px;
  padding: 5px;
  cursor: pointer;
}

.signup {
  display: flex;
  align-items: baseline;
}

.login h4 {
  margin: 0;
}

.link {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: 16px;
}
