.container {
  display: flex;
  justify-content: center;
}

.search {
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid rgb(186, 184, 184);
  padding: 10px;
  width: 450px;
}

@media screen and (max-width: 900px) and (min-width: 430px) {
  .search {
    width: 50%;
  }
}

@media screen and (max-width: 430px) {
  .search {
    width: 200px;
  }
}
