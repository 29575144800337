.navbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(186, 184, 184);
  padding: 10px 15px;
  background-color: #fae2de;
}

.brandName {
  font-weight: 500;
  margin: 0 5px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  opacity: 0.6;
}

.selected {
  opacity: 1;
}

.brandName:hover {
  opacity: 1;
}

.icon {
  max-width: 20px;
  margin-right: 5px;
}

.btn {
  margin: auto 5px auto auto;
  border-radius: 4px;
  border: 1px solid rgb(186, 184, 184);
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
}

.login {
  margin: auto 5px auto auto;
}

.disappear {
  display: none;
}
