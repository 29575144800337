@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading_spinner {
  width: 60px;
  height: 60px;
  animation: spinner 1.5s linear infinite;
}

.spinner_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
}
